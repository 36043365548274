import React, { useState } from 'react';

export default function SelectQuestion({ question, options, onAnswer }) {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleAnswer = (option, index) => {    
        onAnswer(option);
    };
    return (
        <div>
            <div className="chat chat-start">
                <div className="chat-bubble mb-2 chatbot-msg" >{question}</div>
            </div>
            <div className="option-selection">
                {options.map((option, index) => (
                    <button className="btn btn-secondary option"
                        key={index}
                        onClick={() => handleAnswer(option, index)}
                    >
                        {option}
                    </button>
                ))}
            </div>
        </div>
    );
}