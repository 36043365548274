import React, { useState, useEffect } from "react";
import script from "../script.json";
// import multifillq from "../multifillq.json";
// import shortscript from "../shortscript.json";
import YesNoQuestion from "./YesNoQuestion";
import FillQuestion from "./FillQuestion";
import SelectQuestion from "./SelectQuestion";
import MultiSelectQuestion from "./MultiSelectQuestion";
import MultiFillQuestion from "./MultiFillQuestion";
import FinalPage from "./FinalPage";
import { Button } from "@aws-amplify/ui-react";

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// needs to be declared outside so doesn't override it on every reset
let questions;
const ResetQuestions = () => {
  questions = structuredClone(script);
  // questions = structuredClone(multifillq);
  // questions = structuredClone(shortscript);
};

ResetQuestions();

export default function Chat() {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userResponses, setUserResponses] = useState([]);
  const [userResponse, setUserResponse] = useState("");
  const [outputJSON, setOutputJSON] = useState("");
  const [showTyping, setShowTyping] = useState(false);
  const [currentResponses, setCurrentResponses] = useState([]); // Track responses for the current question
  const [userInput, setUserInput] = useState("");

  const sessionStorage = window.sessionStorage;
  const localStorage = window.localStorage;

  const chatWindowRef = React.useRef(null);

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("currentQuestionIndex")) {
      const currentQIndexResponse = JSON.parse(
        sessionStorage.getItem("currentQuestionIndex")
      );
      setCurrentQuestionIndex(currentQIndexResponse);
    } else {
      //this means first time loading page, clear local storage to make it behave like session storage
      localStorage.clear();
      localStorage.removeItem("userResponses");
    }
    if (sessionStorage.getItem("currentResponses")) {
      const currentResponses = JSON.parse(
        sessionStorage.getItem("currentResponses")
      );
      setCurrentResponses(currentResponses);
    }
    if (sessionStorage.getItem("outputJSON")) {
      const outputJSONResponse = JSON.parse(
        sessionStorage.getItem("outputJSON")
      );
      setOutputJSON(outputJSONResponse);
    }
    if (localStorage.getItem("userResponses")) {
      const response = JSON.parse(localStorage.getItem("userResponses"));
      setUserResponses(response);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userResponses.length > 0) {
      localStorage.setItem("userResponses", JSON.stringify(userResponses));
    }
    if (currentQuestionIndex !== 0) {
      sessionStorage.setItem("currentQuestionIndex", currentQuestionIndex);
    }
    if (currentResponses.length > 0) {
      sessionStorage.setItem(
        "currentResponses",
        JSON.stringify(currentResponses)
      );
    }
    const outputLength = Object.keys(outputJSON).length;
    if (outputLength > 0) {
      sessionStorage.setItem("outputJSON", JSON.stringify(outputJSON));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userResponses, currentQuestionIndex, currentResponses, outputJSON]);

  useEffect(() => {
    scrollToBottom();
  }, [userResponses, showTyping]);

  const handleAnswer = (response) => {
    const currentQuestion = questions[currentQuestionIndex];

    const responseKey = `${currentQuestion["question-number"]}`;

    const updatedOutputJSON = {
      ...outputJSON,
      [responseKey]: response,
    };

    // console.log(JSON.stringify(updatedOutputJSON, null, 2));

    setOutputJSON(updatedOutputJSON);

    let formattedResponse = response;

    if (Array.isArray(response)) {
      // If the response is an array, join its elements with commas
      formattedResponse = response.join(", ");
    }

    const newUserResponse = [currentQuestion.question, formattedResponse];

    setUserResponses((prevUserResponses) => [
      ...prevUserResponses,
      newUserResponse,
    ]);
    setUserResponse("");

    if (currentQuestion["sub-questions"]) {
      handleSubQuestions(currentQuestion["sub-questions"], response);
    }

    setShowTyping(true);

    // Check if the response is No and the current question number is 43
    if (response === "No" && currentQuestion["question-number"] === "Q43") {
      // Skip questions between Q#43 and Q#53
      const nextQuestionIndex = questions.findIndex(
        (question) => question["question-number"] === "Q53"
      );
      setCurrentQuestionIndex(nextQuestionIndex);
    } else if (
      ["Q44", "Q45", "Q46", "Q46.1"].includes(
        currentQuestion["question-number"]
      )
    ) {
      // Check if responses to Q #44, #45, #46, and #46.1 are all No
      const allNoResponses = ["Q44", "Q45", "Q46", "Q46.1"].every((qNum) => {
        return updatedOutputJSON[qNum] === "No";
      });

      // Check if responses to Q #44, #45, #46, and #46.1 contain at least one Yes
      const anyYesResponse = ["Q44", "Q45", "Q46", "Q46.1"].some((qNum) => {
        return updatedOutputJSON[qNum] === "Yes";
      });

      if (allNoResponses) {
        // Proceed to Question #53
        const nextQuestionIndex = questions.findIndex(
          (question) => question["question-number"] === "Q53"
        );
        setCurrentQuestionIndex(nextQuestionIndex);
      } else if (anyYesResponse) {
        // CHECK ABOUT THIS LOGIC?? IT DOESNT MAKE SENSE! IF 44 IS YES WE SKIP OVER EVERYTHING AND GO TO 47 AND NEVER ADDRESS THE OTHER Q's!!
        // If the patient responds with Yes to any one of Q #44, #45, #46, and #46.1, proceed to Q #47
        const nextQuestionIndex = questions.findIndex(
          (question) => question["question-number"] === "Q47"
        );
        setCurrentQuestionIndex(nextQuestionIndex);
      } else {
        // Proceed to the next question
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
    } else {
      // Proceed to the next question
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const handleSubQuestions = (subQuestions, response) => {
    const insertIndex = currentQuestionIndex + 1;

    for (let i = subQuestions.length - 1; i >= 0; i--) {
      const subQuestion = subQuestions[i];
      if (Array.isArray(response)) {
        // If the response is an array, join its elements with commas
        for (let i = response.length - 1; i >= 0; i--) {
          if (subQuestion["response-trigger"] === response[i]) {
            questions.splice(insertIndex, 0, subQuestion);
          }
        }
      } else {
        if (subQuestion["response-trigger"] === response) {
          questions.splice(insertIndex, 0, subQuestion);
        }
      }
    }
  };

  const handleAddResponse = (response) => {
    const currentQuestion = questions[currentQuestionIndex];
    // early validation leaving it out for now
    if (currentQuestion["question-number"] === "Q109Aa") {
      const checkValidResponse = response.split(",");
      if (checkValidResponse.length !== 3) {
        alert(
          "Please enter a proper format for your medications Eg.  Tylenol, in the morning and night, 500mg in the morning and 1000mg at night."
        );
        return;
      }
    }
    setCurrentResponses((prevResponses) => [...prevResponses, response]);
  };

  const handleRemoveResponse = (index) => {
    setCurrentResponses((prevResponses) => {
      const updatedResponses = [...prevResponses];
      updatedResponses.splice(index, 1);
      return updatedResponses;
    });
  };

  const handleMultiFillAnswer = () => {
    if (currentResponses.length > 0) {
      const currentQuestion = questions[currentQuestionIndex];
      const responseKey = `${currentQuestion["question-number"]}`;

      const updatedOutputJSON = {
        ...outputJSON,
        [responseKey]: currentResponses,
      };

      // console.log(JSON.stringify(updatedOutputJSON, null, 2));

      setOutputJSON(updatedOutputJSON);

      let formattedResponse = currentResponses;

      if (Array.isArray(currentResponses)) {
        // If the response is an array, join its elements with commas
        formattedResponse = currentResponses.join(", ");
      }

      const newUserResponse = [currentQuestion.question, formattedResponse];

      setUserResponses((prevUserResponses) => [
        ...prevUserResponses,
        newUserResponse,
      ]);
      setUserResponse("");

      // Reset the current responses state
      setCurrentResponses([]);

      if (currentQuestion["sub-questions"]) {
        handleSubQuestions(currentQuestion["sub-questions"], currentResponses);
      }

      setShowTyping(true);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const renderChatHistory = () => {
    return (
      <div ref={chatWindowRef} className="chat-history">
        {userResponses.map(([question, response], index) => (
          <div key={index}>
            <div className="chat chat-start">
              <div className="chat-bubble mb-2 chatbot-msg">{question}</div>
            </div>
            <div className="chat chat-end">
              <div className="chat-bubble mb-2 user-msg">{response}</div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderNextQuestion = () => {
    if (!showTyping) {
      return (
        <div>
          {renderQuestion()}
          {showTyping && (
            <div className="chat chat-start">
              <div className="chat-bubble chatbot-msg pt-4">
                <div className="typing__dot"></div>
                <div className="typing__dot"></div>
                <div className="typing__dot"></div>
              </div>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    const typingDelay = async () => {
      await sleep(400); // Simulate typing delay
      setShowTyping(false);
      scrollToBottom(); // Scroll to the bottom after typing is complete
    };

    if (showTyping) {
      typingDelay();
    }
  }, [showTyping, userResponses]);

  const renderQuestion = () => {
    const currentQuestion = questions[currentQuestionIndex];

    switch (currentQuestion.type) {
      case "fill":
        return (
          <FillQuestion
            question={currentQuestion.question}
            onAnswer={handleAnswer}
          />
        );
      case "yn":
        return (
          <YesNoQuestion
            question={currentQuestion.question}
            onAnswer={handleAnswer}
          />
        );
      case "select":
        return (
          <SelectQuestion
            question={currentQuestion.question}
            options={currentQuestion.options.map(
              (option) => option["option-name"]
            )}
            onAnswer={handleAnswer}
          />
        );
      case "multiselect":
        return (
          <MultiSelectQuestion
            question={currentQuestion.question}
            options={currentQuestion.options.map(
              (option) => option["option-name"]
            )}
            onAnswer={handleAnswer}
          />
        );
      case "multifill":
        return (
          <MultiFillQuestion
            question={questions[currentQuestionIndex].question}
            onAddResponse={handleAddResponse}
            onRemoveResponse={handleRemoveResponse}
            onSubmit={handleMultiFillAnswer}
            currentResponses={currentResponses}
          />
        );
      default:
        return null;
    }
  };

  const handleInputChange = (e) => {
    setUserResponse(e.target.value);
  };

  const handleFillAnswer = () => {
    handleAnswer(userResponse);
  };
  const ResetForm = () => {
    if (localStorage.getItem("userResponses")) {
      localStorage.setItem("userResponses", []);
      setUserResponses([]);
    }
    if (sessionStorage.getItem("currentQuestionIndex")) {
      sessionStorage.setItem("currentQuestionIndex", 0);
      setCurrentQuestionIndex(0);
    }
    if (sessionStorage.getItem("currentResponses")) {
      sessionStorage.setItem("currentResponses", []);
      setCurrentResponses([]);
    }
    if (sessionStorage.getItem("outputJSON")) {
      sessionStorage.setItem("outputJSON", "");
      setOutputJSON("");
    }
    // this removes all the subquestions that were added from the previous runthrough
    ResetQuestions();
  };

  if (!questions[currentQuestionIndex]) {
    return (
      <div className="card bg-base-100 m-auto w-6/12 p-6 final-page">
        <Button onClick={ResetForm}>Reset Form</Button>
        <FinalPage responses={outputJSON} />
      </div>
    );
  }

  const handleAddMultiResponse = () => {
    if (userInput.trim() !== "") {
      handleAddResponse(userInput.trim());
      setUserInput("");
    }
  };

  return (
    <div>
      <div className="card bg-base-100 m-auto w-6/12 p-6 chatbox">
        <Button onClick={ResetForm}>Reset Form</Button>
        <div id="chat-window" ref={chatWindowRef}>
          {renderChatHistory()}

          {showTyping && (
            <div className="chat chat-start">
              <div className="chat-bubble chatbot-msg pt-4">
                <div className="typing__dot"></div>
                <div className="typing__dot"></div>
                <div className="typing__dot"></div>
              </div>
            </div>
          )}
          {renderNextQuestion()}
        </div>
        {!showTyping && questions[currentQuestionIndex].type === "fill" && (
          <div className="user-input mt-auto w-full flex">
            <input
              type="text"
              className="input-field"
              value={userResponse}
              onChange={handleInputChange}
              onKeyPress={(e) => {
                if (e.key === "Enter" && userResponse.trim() !== "") {
                  handleFillAnswer();
                }
              }}
              placeholder="Your response"
            />
            <button
              className="send-btn btn btn-info"
              onClick={handleFillAnswer}
              disabled={userResponse.trim() === ""}
            >
              Send
            </button>
          </div>
        )}
        {!showTyping &&
          questions[currentQuestionIndex].type === "multifill" && (
            <div className="multifill-container">
              <div className="input-add-container">
                <input
                  type="text"
                  className="multifill-input-field"
                  value={userInput}
                  onChange={(e) => setUserInput(e.target.value)}
                  placeholder="Your response"
                />
                <button
                  className="btn btn-info add-btn"
                  onClick={handleAddMultiResponse}
                  disabled={userInput.trim() === ""}
                >
                  Add
                </button>
              </div>
              <div className="multifill-btn-container">
                <button
                  className="submit-btn btn btn-info"
                  onClick={handleMultiFillAnswer}
                  disabled={currentResponses.length === 0}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
