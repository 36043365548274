import React, { useState, useEffect } from 'react';

export default function FillQuestion({ question, onAnswer }) {
  const [userResponse, setUserResponse] = useState('');

  useEffect(() => {
    setUserResponse('');
  }, [question]);

  return (
    <div className="fill-question-container">
      <div className="chat chat-start">
        <div className="chat-bubble mb-2 chatbot-msg">{question}</div>
      </div>
    </div>
  );
}
