import "../styles/App.css";
import PropTypes from "prop-types";

// amplify default components, just for show, can customize as needed
import { Amplify } from "aws-amplify";
import { Link } from "react-router-dom";
import { Authenticator, Button } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import config from "../amplifyconfiguration.json";
import "@aws-amplify/ui-react/styles.css";
Amplify.configure(config);

// Main component that manages the state of the application
const DoctorAccessPage = () => {
  return (
    <>
      <Link to="/">
        <Button>Home</Button>
      </Link>
      <Authenticator>
        {({ signOut, user }) => (
          <div className="">
            {/* Conditional rendering based on whether to show Disclaimer or Chat */}
            {/* <Heading level={5}>Hello {user.username}</Heading> */}
            <nav>
              <ul>
                <li>Add {user.signInDetails.loginId}</li>
                <li>Questions</li>
                <li>Here</li>
              </ul>
            </nav>
            <Button onClick={signOut}>Sign out</Button>
          </div>
        )}
      </Authenticator>
    </>
  );
};

DoctorAccessPage.propTypes = {
  signOut: PropTypes.func,
  user: PropTypes.object,
};

export default DoctorAccessPage;
