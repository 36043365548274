import React, { useState } from "react";

export default function MultiSelectQuestion({ question, options, onAnswer }) {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const toggleOption = (option) => {
    // Check if the option is already selected
    const isSelected = selectedOptions.includes(option);
    const selectedRemovedOptions = selectedOptions.filter(
      (selectedOption) => selectedOption !== option
    );

    if (isSelected) {
      setSelectedOptions(() => selectedRemovedOptions);
      return; // don't need to check the rest
    }

    // special condition to check if "None of the above" is selected
    const noneOfTheAboveMessageStartsWith = "I do not";
    const isNoneOfTheAboveOptionSelected = option.includes(
      noneOfTheAboveMessageStartsWith
    );

    // only returns an array if have to remove "none of the above" option from the array
    const removedNoneOfTheAboveOptions = selectedOptions.filter(
      (excludeOption) =>
        !excludeOption.includes(noneOfTheAboveMessageStartsWith)
    );

    // if special selected, uncheck everything else, if special option needs to be removed do that and add new option, else normal
    if (isNoneOfTheAboveOptionSelected) {
      setSelectedOptions(() => [option]);
    } else if (removedNoneOfTheAboveOptions) {
      setSelectedOptions(() => [...removedNoneOfTheAboveOptions, option]);
    } else {
      setSelectedOptions((previousOptions) => [...previousOptions, option]);
    }
  };

  const handleAnswer = () => {
    // Pass the selected options array to the onAnswer callback
    //ensures the array that gets passed to onAnswer only has unique values
    const uniqueSelectedOptions = [...new Set(selectedOptions)];
    console.log("selectedOptions", uniqueSelectedOptions);
    onAnswer(selectedOptions);
  };

  return (
    <div>
      <div className="chat chat-start">
        <div className="chat-bubble mb-2 chatbot-msg">{question}</div>
      </div>
      <div className="option-selection">
        {options.map((option, index) => (
          <button
            className={`btn ${
              selectedOptions.includes(option)
                ? "selected-option"
                : "btn-secondary"
            } disable
            option`}
            key={index}
            onClick={() => toggleOption(option)}
          >
            {option}
          </button>
        ))}
      </div>
      <button className="btn btn-primary" onClick={handleAnswer}>
        Submit
      </button>
    </div>
  );
}
