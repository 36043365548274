export default function YesNoQuestion({ question, onAnswer }) {
    const handleAnswer = (response) => {
        onAnswer(response);
    };

    return (
        <div>
            <div className="chat chat-start">
                <div className="chat-bubble mb-2 chatbot-msg" >{question}</div>
            </div>
            <div className="yn-selection">
                <button className="btn btn-secondary yn-option" onClick={() => handleAnswer('Yes')}>Yes</button>
                <button className="btn btn-secondary yn-option" onClick={() => handleAnswer('No')}>No</button>
            </div>
        </div>
    )
}