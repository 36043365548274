function Disclaimer({onAccept}) {
  return (
    <div className="card bg-base-100 shadow-xl m-auto w-6/12 text-center z-50 p-6">
      <div className="card-body items-center text-center">
        <h2 className="card-title">Before We Begin</h2>
        <p>You are being asked to complete this form as part of the consultation process with a Psychiatrist. Information provided will be forwarded to the consulting Psychiatrist for review prior to your appointment.</p>
        <h3 className="pt-6 card-title">Please Note:</h3>
        <p>You are not expected to answer questions related to past experiences of trauma, and are able to skip these questions, if you do not wish to discuss past traumas at this time. </p>
        <div className="pt-6 card-actions">
          <button className="btn btn-secondary" onClick={onAccept}>Start Intake</button>
        </div>
      </div>
    </div>
  )
}

export default Disclaimer