import React, { useState } from 'react';
import '../styles/MultiFillQuestion.css';

export default function MultiFillQuestion({
    question,
    onAddResponse,
    onRemoveResponse,
    onSubmit,
    currentResponses,
}) {
    const [userInput, setUserInput] = useState('');

    const handleAddResponse = () => {
        if (userInput.trim() !== '') {
            onAddResponse(userInput.trim());
            setUserInput('');
        }
    };

    return (
        <div className="multi-fill-question-container">
            <div className="chat chat-start">
                <div className="chat-bubble mb-2 chatbot-msg">{question}</div>
            </div>
            <div className="user-responses chat chat-end">
                {currentResponses.map((response, index) => (
                    <div key={index} className="response-item">
                        <span>{response}</span>
                        <button className="remove-btn" onClick={() => onRemoveResponse(index)}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="18" viewBox="0 0 13 18" fill="none">
                                <path d="M1.40179 5.29454C0.978612 4.85509 0.978612 4.14142 1.40179 3.70197C1.82497 3.26251 2.51221 3.26251 2.93538 3.70197L6.50023 7.40743L10.0685 3.70548C10.4916 3.26603 11.1789 3.26603 11.6021 3.70548C12.0252 4.14493 12.0252 4.85861 11.6021 5.29806L8.03382 9.00001L11.5987 12.7055C12.0218 13.1449 12.0218 13.8586 11.5987 14.2981C11.1755 14.7375 10.4882 14.7375 10.0651 14.2981L6.50023 10.5926L2.932 14.2945C2.50882 14.734 1.82158 14.734 1.3984 14.2945C0.975227 13.8551 0.975227 13.1414 1.3984 12.702L4.96663 9.00001L1.40179 5.29454Z" fill="#575C7B" />
                            </svg>
                        </button>
                    </div>
                ))}
            </div>
            {/* <div className="multifill-input">
                <input
                    type="text"
                    className="input-field"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    placeholder="Your response"
                />
                <button  className="btn btn-info add-btn" onClick={handleAddResponse} disabled={userInput.trim() === ''}>
                    Add
                </button>
            </div> */}
            {/* <button className="submit-btn" onClick={onSubmit} disabled={currentResponses.length === 0}>
                Submit
            </button> */}
        </div>
    );
}
