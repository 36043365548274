import React, { useState } from "react";

const FinalPage = ({ responses }) => {
  const [loading, setLoading] = useState(false);
  const [documentUrl, setDocumentUrl] = useState(null);

  const apiUrl =
    "https://ri396f7jl9.execute-api.ca-central-1.amazonaws.com/develop/v1/intake-answers";

  let urlValue = "";

  const requestOptions = {
    method: "POST",
    headers: {
      "x-api-key": "JgOxWir7dd37CxVifZFww3hk0td5bOE7xrcqF1E7",
    },
    body: JSON.stringify(responses),
  };

  const generateDocument = async () => {
    try {
      setLoading(true);
      const response = await fetch(apiUrl, requestOptions);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const responseData = await response.json();
      const base64String = responseData.encodedContent;

      // Convert Base64 string to a Blob
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });

      // Create a URL for the Blob
      const url = URL.createObjectURL(blob);
      setDocumentUrl(url);
    } catch (error) {
      console.error("Error generating document:", error);
    } finally {
      setLoading(false);
    }
  };

  const getFormattedDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    const seconds = now.getSeconds().toString().padStart(2, "0");
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
  };

  const downloadDocument = () => {
    const formattedDateTime = getFormattedDateTime();
    const fileName = `${formattedDateTime}.docx`; // Filename with date and time
    const link = document.createElement("a");
    link.href = documentUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      {loading ? (
        <div>
          <p>Generating your document...</p>
          <span className="loading loading-spinner"></span>
        </div>
      ) : documentUrl ? (
        <div>
          <p>Your document is ready to be viewed.</p>
          <button className="btn btn-primary" onClick={downloadDocument}>
            Download Document
          </button>
        </div>
      ) : (
        <div>
          <p>
            Thank you for completing the intake form. This information will now
            be made available to the doctor.
          </p>
          <button className="btn btn-primary" onClick={generateDocument}>
            Generate Document
          </button>
        </div>
      )}
    </div>
  );
};

export default FinalPage;
