import "../styles/App.css";
import Disclaimer from "./Disclaimer";
import Chat from "./Chat";
import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import { Button } from "@aws-amplify/ui-react";

// Main component that manages the state of the application
const App = () => {
  const [showChat, setShowChat] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("showChat")) {
      const showChat = JSON.parse(sessionStorage.getItem("showChat"));
      if (showChat === true) {
        setShowChat(showChat);
      }
    }
  }, []);

  useEffect(() => {
    console.log("showChat: " + showChat);
    sessionStorage.setItem("showChat", showChat);
  }, [showChat]);

  const DisclaimerOrChat = () => {
    let theChosenElement = <Disclaimer onAccept={() => setShowChat(true)} />;
    if (showChat) {
      theChosenElement = <Chat />;
    }
    return theChosenElement;
  };
  return (
    <>
      <div className="w-full relative min-h-screen flex flex-col background-gradient">
        {/* Conditional rendering based on whether to show Disclaimer or Chat */}
        <nav>
          <ul>
            {/* <Link to="/doctors_access">
              <Button>Doctor Access</Button>
            </Link> */}
          </ul>
        </nav>
        <DisclaimerOrChat />
      </div>
    </>
  );
};

export default App;
